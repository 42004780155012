










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JwlButton from './JwlButton.vue';

export interface NavTab {
  target: string;
  label: string;
}

@Component({
  components: {
    JwlButton,
  },
})
export default class JwlSectionNav extends Vue {
   @Prop(Array)
  navigationNodes!: NavTab[];
}
