






import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import JwlSectionNav from '@/components/jwlSectionNav.vue';

@Component({
  components: {
    JwlSectionNav,
  },
  computed: mapState(['currentUser']),
})
export default class JwlSupport extends Vue {}
